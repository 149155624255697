import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../firebase.init";
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import { signOut } from "firebase/auth";

const Profile = () => {
  const [user] = useAuthState(auth);
  const email = user.email;
  const splittedEmail = email.split("@");
  const regNum = splittedEmail[0];
  const [loading, setLoading] = useState(false);
  const [registeredUser, setRegisteredUser] = useState({});

  useEffect(() => {
    const data = async () => {
      setLoading(true);
      await fetch(
        `https://shihabs-tutorial-backend-1.onrender.com/user/${regNum}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((res) => setRegisteredUser(res));
      setLoading(false);
    };
    data();
  }, [regNum]);

  if (loading) {
    return (
      <div className="container mx-auto">
        <div className="flex justify-between px-5 lg:px-40 pt-7 pb-4 items-center">
          <h1 className="text-2xl font-semibold">My Profile</h1>
          <Link
            to="editProfile"
            className="text-lg text-primary font-semibold flex items-center link link-hover"
          >
            <FaPencilAlt className="mr-1 text-sm" />
            Edit
          </Link>
        </div>
        <div className="divider"></div>
        <div className="profile-information">
          <div className="lg:flex px-5 justify-center items-center">
            <div className="profile-image flex flex-col items-center">
              <div className="rounded-full w-60 h-60 object-cover mx-auto skeleton bg-gray-200"></div>
              <Link
                to="editProfile"
                className="btn lg:mb-0 mb-10  btn-primary rounded-full text-white px-10 mt-3"
              >
                Edit Profile
              </Link>
            </div>
            <div className="profile-infos text-left lg:ml-10">
              <div className="info mb-3">
                <span class="label-text text-sm font-bold text-[#646464]">
                  Registration Number:
                </span>
                <div className="skeleton h-4 w-full"></div>
              </div>
              <div className="info mb-3">
                <span class="label-text text-sm font-bold text-[#646464]">
                  Name:
                </span>
                <div className="skeleton bg-gray-200 h-4 w-full"></div>
              </div>
              <div className="info mb-3">
                <span class="label-text text-sm font-bold text-[#646464]">
                  Institute Name:
                </span>
                <div className="skeleton bg-gray-200 h-4 w-full"></div>
              </div>
              <div className="info mb-3">
                <span class="label-text text-sm font-bold text-[#646464]">
                  Mobile Number:
                </span>
                <div className="skeleton bg-gray-200 h-4 w-full"></div>
              </div>
              <div className="info mb-3">
                <span class="label-text text-sm font-bold text-[#646464]">
                  Class:
                </span>
                <div className="skeleton bg-gray-200 h-4 w-full"></div>
              </div>
              <div className="info mb-3">
                <span class="label-text text-sm font-bold text-[#646464]">
                  Gender:
                </span>
                <div className="skeleton bg-gray-200 h-4 w-full"></div>
              </div>
              <div className="info mb-3">
                <span class="label-text text-sm font-bold text-[#646464]">
                  Date of Birth:
                </span>
                <div className="skeleton bg-gray-200 h-4 w-full"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto">
      <div className="flex justify-between px-5 lg:px-40 pt-7 pb-4 items-center">
        <h1 className="text-2xl font-semibold">My Profile</h1>
        <Link
          to="editProfile"
          className="text-lg text-primary font-semibold flex items-center link link-hover"
        >
          <FaPencilAlt className="mr-1 text-sm" />
          Edit
        </Link>
      </div>
      <div className="divider"></div>
      <div className="profile-information">
        <div className="lg:flex px-5 justify-center items-center">
          <div className="profile-image flex flex-col items-center">
            <img
              className="rounded-full w-60 h-60 object-cover mx-auto border-2"
              src={registeredUser.pp}
              alt="profile"
            />
            <Link
              to="editProfile"
              className="btn lg:mb-0  btn-primary rounded-full text-white px-10 mt-3"
            >
              Edit Profile
            </Link>
            <button
              className="btn lg:mb-0 mb-10  btn-error rounded-full text-white px-10 mt-3"
              onClick={() => {
                signOut(auth);
              }}
            >
              Logout
            </button>
          </div>
          <div className="profile-infos text-left lg:ml-10">
            <div className="info mb-3">
              <span class="label-text text-sm font-bold text-[#646464]">
                Registration Number:
              </span>
              <h4 className="text-lg">{registeredUser.registrationNumber}</h4>
            </div>
            <div className="info mb-3">
              <span class="label-text text-sm font-bold text-[#646464]">
                Name:
              </span>
              <h4 className="text-lg">{registeredUser.name}</h4>
            </div>
            <div className="info mb-3">
              <span class="label-text text-sm font-bold text-[#646464]">
                Institute Name:
              </span>
              <h4 className="text-lg">{registeredUser.institutionName}</h4>
            </div>
            <div className="info mb-3">
              <span class="label-text text-sm font-bold text-[#646464]">
                Mobile Number:
              </span>
              <h4 className="text-lg">{registeredUser.mobileNumber}</h4>
            </div>
            <div className="info mb-3">
              <span class="label-text text-sm font-bold text-[#646464]">
                Class:
              </span>
              <h4 className="text-lg">Class-{registeredUser.stClass}</h4>
            </div>
            <div className="info mb-3">
              <span class="label-text text-sm font-bold text-[#646464]">
                Gender:
              </span>
              <h4 className="text-lg">{registeredUser.gender}</h4>
            </div>
            <div className="info mb-3">
              <span class="label-text text-sm font-bold text-[#646464]">
                Date of Birth:
              </span>
              <h4 className="text-lg">{registeredUser.dob}</h4>
            </div>
            <div className="info mb-3">
              <span class="label-text text-sm font-bold text-[#646464]">
                SSC Roll:
              </span>
              <h4 className="text-lg">{registeredUser.sscRoll ? registeredUser.sscRoll : "Not found"}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
