import React, { useEffect } from "react";
import {
  useAuthState,
  useSignInWithEmailAndPassword,
} from "react-firebase-hooks/auth";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import auth from "../firebase.init";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Login = () => {
  let location = useLocation();
  const [user] = useAuthState(auth);
  const [signInWithEmailAndPassword, emailUser, emailLoading, emailError] =
    useSignInWithEmailAndPassword(auth);
  const { handleSubmit, register } = useForm();
  const onSubmit = (data) => {
    signInWithEmailAndPassword(data.regnum + "@gmail.com", data.mobileNumber);
  };

  useEffect(() => {
    if (emailError) {
      const newErrorMessage = emailError?.message
        .split("Firebase: Error (auth/")
        .join("")
        .split(").")
        .join("")
        .split("-")
        .join(" ");
      toast.error(newErrorMessage.toUpperCase());
    }
  }, [emailError]);

  if (user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return (
    <div class="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12 bg-slate">
      <div class="relative py-3 sm:max-w-xl sm:mx-auto">
        <div class="absolute inset-0 bg-gradient-to-r from-blue-300 to-blue-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div class="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div class="max-w-md mx-auto">
            <div>
              <h1 class="text-2xl font-semibold">Login at Talent Hunt 2024</h1>
            </div>
            <div class="divide-y divide-gray-200">
              <form
                onSubmit={handleSubmit(onSubmit)}
                class="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7"
              >
                <div class="relative">
                  <label for="registrationNumber" class="label-text">
                    Registration Number
                  </label>
                  <input
                    id="registrationNumber"
                    name="registrationNumber"
                    type="number"
                    class="input input-bordered w-full"
                    placeholder="Registration Number"
                    required
                    {...register("regnum", {
                      required: true,
                    })}
                  />
                </div>
                <div class="relative mb-5">
                  <label for="registrationNumber" class="label-text">
                    Mobile Number
                  </label>
                  <input
                    id="registrationNumber"
                    name="registrationNumber"
                    type="number"
                    class="input input-bordered w-full"
                    placeholder="Mobile Number"
                    required
                    {...register("mobileNumber", {
                      required: true,
                    })}
                  />
                </div>

                <Link className="text-[14px] text-blue-600" to="/">
                  Didn't register? Register now
                </Link>
                <div class="relative">
                  <button class="bg-blue-500 text-white rounded-md px-5 py-1">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
