import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../firebase.init";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";

const EditProfile = () => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [user] = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const email = user.email;
  const splittedEmail = email.split("@");
  const regNum = splittedEmail[0];
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    const data = async () => {
      setLoading(true);
      await fetch(
        `https://shihabs-tutorial-backend-1.onrender.com/user/${regNum}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((res) => setUserInfo(res));
      setLoading(false);
    };
    data();
  }, [regNum]);

  if (loading) {
    return (
      <div className="profile-information lg:px-52 pb-10">
        <div class="flex justify-between my-5 lg:px-0 px-5 bg-base-100">
          <Link
            to="/"
            class="bg-primary w-20 flex items-center justify-center gap-2 rounded text-white"
          >
            <FaArrowLeft></FaArrowLeft>Back
          </Link>
          <h4 className="text-2xl font-bold">Edit Profile</h4>
        </div>
        <div className="flex px-5 lg:px-20 justify-center items-center">
          <div className="profile-infos text-left lg:ml-10 w-full">
            <div>
              <div class="animate-pulse p-4 space-y-4">
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">
                      Registration Number (not changeable)
                    </span>
                  </label>
                  <div className="bg-gray-200 rounded-md w-full h-12" />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Name</span>
                  </label>
                  <div className="bg-gray-200 rounded-md w-full h-12" />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">
                      Mobile Number (not changeable)
                    </span>
                  </label>
                  <div className="bg-gray-200 rounded-md w-full h-12" />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Institution Name</span>
                  </label>
                  <div className="bg-gray-200 rounded-md w-full h-12" />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Class</span>
                  </label>
                  <div className="bg-gray-200 rounded-md w-full h-12" />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Address</span>
                  </label>
                  <div className="bg-gray-200 rounded-md w-full h-12" />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Date of Birth</span>
                  </label>
                  <div className="bg-gray-200 rounded-md w-full h-12" />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">SSC Roll</span>
                  </label>
                  <div className="bg-gray-200 rounded-md w-full h-12" />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Profile Picture</span>
                  </label>
                  <div className="bg-gray-200 rounded-md w-full h-12" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  let changes = {
    name: userInfo?.name,
    pp: userInfo?.pp,
    institutionName: userInfo?.institutionName,
    stClass: userInfo?.stClass,
    gender: userInfo?.gender,
    dob: userInfo?.dob,
    address: userInfo?.address,
    sscRoll: userInfo?.sscRoll,
    mobileNumber: userInfo?.mobileNumber,
    registrationNumber: userInfo?.registrationNumber,
  };
  let errors = {
    name: "",
    pp: "",
    institutionName: "",
    mobileNumber: "",
    stClass: "",
    gender: "",
    dob: "",
    address: "",
    sscRoll: "",
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    // toast("Profile updated successfully", { type: "success" });
    setLoading(true);
    const image = data.photo[0];
    if (image) {
      const imageStorageKey = "25f8fd66fcd0b291d11ff45ad0f16374";
      const url = `https://api.imgbb.com/1/upload?key=${imageStorageKey}`;
      const formData = new FormData();
      formData.append("image", image);
      await axios.post(url, formData).then((res) => {
        if (res.data.success) {
          const updatePhotoUrl = async () => {
            const photoUrl = res.data.data.url;
            console.log(photoUrl);
            changes = { ...changes, pp: photoUrl };
            fetch(`https://shihabs-tutorial-backend-1.onrender.com/update-user/${userInfo._id}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(changes),
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.acknowledged) {
                  console.log(data);
                  toast.success("Profile Updated");
                }
              })
              .catch((error) => {
                toast.error(error);
              });
          };
          updatePhotoUrl();
        }
      });
    } else {
      fetch(`https://shihabs-tutorial-backend-1.onrender.com/update-user/${userInfo._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(changes),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.acknowledged) {
            console.log(data);
            toast.success("Profile Updated");
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
    setLoading(false);
  };

  return (
    <div className="profile-information lg:px-52 pb-10">
      <div class="flex justify-between my-5 lg:px-0 px-5 bg-base-100">
        <Link
          to="/"
          class="bg-primary w-20 flex items-center justify-center gap-2 rounded text-white"
        >
          <FaArrowLeft></FaArrowLeft>Back
        </Link>
        <h4 className="text-2xl font-bold">Edit Profile</h4>
      </div>
      <div className="flex px-5 lg:px-20 justify-center items-center">
        <div className="profile-infos text-left lg:ml-10 w-full">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-control">
              <label className="label" htmlFor="registrationNumber">
                <span className="label-text">
                  Registration Number (not changeable)
                </span>
              </label>
              <input
                type="text"
                id="registrationNumber"
                disabled
                className="input input-bordered"
                defaultValue={userInfo.registrationNumber}
              />
            </div>
            <div className="form-control">
              <label className="label" htmlFor="name">
                <span className="label-text">Name</span>
              </label>
              <input
                type="text"
                id="name"
                className="input input-bordered"
                defaultValue={changes.name}
                onChange={(e) =>
                  (changes = { ...changes, name: e.target.value })
                }
              />
            </div>
            <div className="form-control">
              <label className="label" htmlFor="mobileNumber">
                <span className="label-text">
                  Mobile Number (not changeable)
                </span>
              </label>
              <input
                type="text"
                id="mobileNumber"
                disabled
                className="input input-bordered"
                defaultValue={userInfo.mobileNumber}
              />
            </div>
            <div className="form-control">
              <label className="label" htmlFor="name">
                <span className="label-text">Institution Name</span>
              </label>
              <input
                type="text"
                id="name"
                className="input input-bordered"
                defaultValue={changes.institutionName}
                onChange={(e) => {
                  if (e.target.value.length === "") {
                    errors = {
                      ...errors,
                      institutionName: "Institution name is required",
                    };
                  } else {
                    errors = { ...errors, institutionName: "" };
                    changes = { ...changes, institutionName: e.target.value };
                  }
                }}
              />
            </div>
            <div className="form-control">
              <label className="label" htmlFor="name">
                <span className="label-text">Class</span>
              </label>
              <select
                class="select select-bordered"
                defaultValue={changes.stClass}
                onChange={(e) => {
                  if (e.target.value.length === "") {
                    errors = { ...errors, stClass: "Class is required" };
                  } else {
                    errors = { ...errors, stClass: "" };
                    changes = { ...changes, stClass: e.target.value };
                  }
                }}
              >
                <option value="">Select your class</option>
                <option value="8">Class 8</option>
                <option value="9">Class 9</option>
                <option value="10">Class 10</option>
                <option value="11">Class 11</option>
                <option value="12">Class 12</option>
              </select>
            </div>
            <div className="form-control">
              <label className="label" htmlFor="address">
                <span className="label-text">Address</span>
              </label>
              <input
                type="text"
                id="address"
                className="input input-bordered"
                defaultValue={changes.address}
                onChange={(e) => {
                  if (e.target.value.length === "") {
                    errors = {
                      ...errors,
                      address: "Address is required",
                    };
                  } else {
                    errors = { ...errors, address: "" };
                    changes = { ...changes, address: e.target.value };
                  }
                }}
              />
            </div>
            <div className="form-control">
              <label className="label" htmlFor="dob">
                <span className="label-text">Date of Birth</span>
              </label>
              <input
                defaultValue={changes.dob}
                onChange={(e) =>
                  (changes = { ...changes, dob: e.target.value })
                }
                type="date"
                required
                class="input input-bordered"
              />
            </div>
            <div className="form-control">
              <label className="label" htmlFor="sscRoll">
                <span className="label-text">SSC Roll</span>
              </label>
              <input
                type="text"
                id="name"
                className="input input-bordered"
                defaultValue={changes.sscRoll}
                onChange={(e) => {
                  changes = { ...changes, sscRoll: e.target.value };
                }}
              />
            </div>
            <div class="flex">
              <div className="form-control">
                <label className="label cursor-pointer gap-2">
                  <span className="label-text">Male</span>
                  <input
                    type="radio"
                    name="radio-10"
                    className="radio checked:bg-red-500"
                    defaultChecked={changes.gender === "Male" && true}
                    onChange={() => (changes = { ...changes, gender: "Male" })}
                  />
                </label>
              </div>
              <div className="form-control">
                <label className="label cursor-pointer gap-2">
                  <span className="label-text">Female</span>
                  <input
                    type="radio"
                    name="radio-10"
                    defaultChecked={changes.gender === "Female" && true}
                    className="radio checked:bg-blue-500"
                    onChange={() =>
                      (changes = { ...changes, gender: "Female" })
                    }
                  />
                </label>
              </div>
            </div>

            <div className="form-control">
              <label className="label" htmlFor="photo">
                <span className="label-text">Profile Picture</span>
              </label>
              <div className="grid lg:grid-cols-12 grid-cols-1 items-center ">
                <div className="col-span-1">
                  <img
                    className="object-cover w-16 h-16 rounded-full mb-2"
                    src={userInfo?.pp}
                    alt="profile"
                  />
                </div>
                <label className="block lg:col-span-11">
                  <span className="sr-only">Choose File</span>
                  <input
                    id="photo"
                    name="photo"
                    type="file"
                    className="pt-1 cursor-pointer text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100 file:cursor-pointer input input-bordered w-full"
                    {...register("photo")}
                    onChange={() => setButtonDisabled(false)}
                  />
                </label>
              </div>
            </div>
            <div className="form-control mt-5">
              <button
                disabled={buttonDisabled}
                className="btn btn-primary text-white"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
