import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useAuthState,
  useCreateUserWithEmailAndPassword,
} from "react-firebase-hooks/auth";
import auth from "../firebase.init";
import axios from "axios";
import { toast } from "react-toastify";
import { FaPencilAlt } from "react-icons/fa";

const Register = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [DOB, setDOB] = useState("");
  const [createUserWithEmailAndPassword] =
    useCreateUserWithEmailAndPassword(auth);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://shihabs-tutorial-backend-1.onrender.com/users")
      .then((response) => setUsers(response.data));
    setLoading(false);
  }, []);

  const random = 24000 + (users.length + 1);

  const [user] = useAuthState(auth);

  const onSubmit = async (info) => {
    const email = random + "@gmail.com";
    const data = {
      ...info,
      gender: gender,
      dob: DOB,
      registrationNumber: random,
      name: name,
      pp: "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png",
    };
    setLoading(true);
    await axios
      .post("https://shihabs-tutorial-backend-1.onrender.com/user", data)
      .then((res) => {
        console.log(res)
        if (res.status === 200) {
          createUserWithEmailAndPassword(email, info.mobileNumber);
          toast.success("Registration Successful");
        }
      })
      .catch((err) => toast.error("Something went wrong"));

    setLoading(false);
  };

  if (loading) {
    return (
      <div className="container mx-auto">
        <div className="flex justify-between px-5 lg:px-40 pt-7 pb-4 items-center">
          <h1 className="text-2xl font-semibold">My Profile</h1>
          <Link
            to="editProfile"
            className="text-lg text-primary font-semibold flex items-center link link-hover"
          >
            <FaPencilAlt className="mr-1 text-sm" />
            Edit
          </Link>
        </div>
        <div className="divider"></div>
        <div className="profile-information">
          <div className="lg:flex px-5 justify-center items-center">
            <div className="profile-image flex flex-col items-center">
              <div className="rounded-full w-60 h-60 object-cover mx-auto skeleton "></div>
              <Link
                to="editProfile"
                className="btn lg:mb-0 mb-10  btn-primary rounded-full text-white px-10 mt-3"
              >
                Edit Profile
              </Link>
            </div>
            <div className="profile-infos text-left lg:ml-10">
              <div className="info mb-3">
                <span class="label-text text-sm font-bold text-[#646464]">
                  Registration Number:
                </span>
                <h4 className="skeleton h-4 w-full"></h4>
              </div>
              <div className="info mb-3">
                <span class="label-text text-sm font-bold text-[#646464]">
                  Name:
                </span>
                <h4 className="skeleton h-4 w-full"></h4>
              </div>
              <div className="info mb-3">
                <span class="label-text text-sm font-bold text-[#646464]">
                  Institute Name:
                </span>
                <h4 className="skeleton h-4 w-full"></h4>
              </div>
              <div className="info mb-3">
                <span class="label-text text-sm font-bold text-[#646464]">
                  Mobile Number:
                </span>
                <h4 className="skeleton h-4 w-full"></h4>
              </div>
              <div className="info mb-3">
                <span class="label-text text-sm font-bold text-[#646464]">
                  Class:
                </span>
                <h4 className="skeleton h-4 w-full"></h4>
              </div>
              <div className="info mb-3">
                <span class="label-text text-sm font-bold text-[#646464]">
                  Gender:
                </span>
                <h4 className="skeleton h-4 w-full"></h4>
              </div>
              <div className="info mb-3">
                <span class="label-text text-sm font-bold text-[#646464]">
                  Date of Birth:
                </span>
                <h4 className="skeleton h-4 w-full"></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  const from = location.state?.from?.pathname || "/";

  if (user) {
    navigate(from);
  }

  return (
    <div class="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div class="relative py-3 sm:max-w-xl sm:mx-auto">
        <div class="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
          <div class="lg:w-[450px] mx-auto">
            <div class="flex items-center space-x-5">
              <div class="block pl-2 font-semibold text-xl self-start text-gray-700">
                <h2 class="leading-relaxed">Register on Talent Hunt 2024</h2>
                <p class="text-sm text-gray-500 font-normal leading-relaxed">
                  Hosted by Shihab's Tutorial
                </p>
              </div>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              class="divide-y divide-gray-200"
            >
              <div class="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                <div class="flex flex-col">
                  <label class="label-text">Name</label>
                  <input
                    type="text"
                    class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                    placeholder="Enter your name"
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  {name.toLowerCase().includes("mishu") && (
                    <p className="font-semibold text-sm text-green-700">
                      Wait , wait ! মিশু তুমি এইনে কি করো 🙁
                    </p>
                  )}
                </div>

                <div class="flex flex-col">
                  <label class="label-text">Institute Name</label>
                  <input
                    type="text"
                    className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                    placeholder="Enter your institute name"
                    {...register("institutionName", {
                      required: true,
                    })}
                  />
                  {errors.institutionName?.type === "required" && (
                    <p className="font-semibold text-sm text-red-700">
                      Institution name is required
                    </p>
                  )}
                </div>
                <div class="flex flex-col">
                  <label class="label-text">
                    Mobile Number (e.g. 01923258931)
                  </label>
                  <input
                    type="text"
                    class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                    placeholder="Enter your mobile number"
                    {...register("mobileNumber", {
                      required: true,
                      pattern: /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/,
                      minLength: 11,
                      maxLength: 11,
                    })}
                  />
                  {errors.mobileNumber?.type === "required" && (
                    <p className="font-semibold text-sm text-red-700">
                      Mobile number is required
                    </p>
                  )}
                  {errors.mobileNumber?.type === "pattern" && (
                    <p className="font-semibold text-sm text-red-700">
                      Provide a valid number
                    </p>
                  )}
                  {errors.mobileNumber?.type === "minLength" && (
                    <p className="font-semibold text-sm text-red-700">
                      Provide a valid number
                    </p>
                  )}
                  {errors.mobileNumber?.type === "maxLength" && (
                    <p className="font-semibold text-sm text-red-700">
                      Provide a valid number
                    </p>
                  )}
                </div>
                <div class="flex flex-col">
                  <label class="label-text">Class</label>
                  <select
                    class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                    placeholder="Enter your institute name"
                    {...register("stClass", {
                      required: true,
                    })}
                  >
                    <option value="">Select your class</option>
                    <option value="8">Class 8</option>
                    <option value="9">Class 9</option>
                    <option value="10">Class 10</option>
                    <option value="11">Class 11</option>
                    <option value="12">Class 12</option>
                  </select>
                  {errors.stClass?.type === "required" && (
                    <p className="font-semibold text-sm text-red-700">
                      Class is required
                    </p>
                  )}
                </div>

                <div class="flex flex-col">
                  <label class="label-text">Date Of Birth</label>
                  <input
                    value={DOB}
                    onChange={(e) => setDOB(e.target.value)}
                    type="date"
                    required
                    class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                  />
                </div>
                <div class="flex flex-col">
                  <label class="label-text">SSC Roll (if any)</label>
                  <input
                    type="text"
                    class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                    placeholder="Enter your ssc roll"
                  />
                </div>
                <div class="flex flex-col">
                  <label class="label-text">Address</label>
                  <input
                    type="text"
                    class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                    placeholder="Enter your Address"
                    {...register("address", {
                      required: true,
                    })}
                  />
                  {errors.address?.type === "required" && (
                    <p className="font-semibold text-sm text-red-700">
                      Address is required
                    </p>
                  )}
                </div>

                {/* <div className="form-control">
                  <label className="label" htmlFor="pp">
                    <span className="label-text">Profile Picture</span>
                  </label>
                  <label class="block">
                    <span class="sr-only">Choose File</span>
                    <input
                      type="file"
                      id="pp"
                      class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100 input input-bordered pt-[5px]"
                      {...register("pp", { required: true })}
                    />
                  </label>
                  {errors.pp?.type === "required" && (
                    <p className="text-red-600 text-left font-semibold">
                      Profile Picture is required
                    </p>
                  )}
                </div> */}

                <div class="flex">
                  <div className="form-control">
                    <label className="label cursor-pointer gap-2">
                      <span className="label-text">Male</span>
                      <input
                        type="radio"
                        name="radio-10"
                        className="radio checked:bg-red-500"
                        onChange={() => setGender("Male")}
                      />
                    </label>
                  </div>
                  <div className="form-control">
                    <label className="label cursor-pointer gap-2">
                      <span className="label-text">Female</span>
                      <input
                        type="radio"
                        name="radio-10"
                        className="radio checked:bg-blue-500"
                        onChange={() => setGender("Female")}
                      />
                    </label>
                  </div>
                </div>

                <Link className="text-[14px] text-blue-600" to="/login">
                  Already registered? Login Now
                </Link>
              </div>
              <div class="pt-0 flex items-center space-x-4">
                <button class="bg-blue-500 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
